@import "./account-form";

$account-background-regular: $colour-ui-light !default;
$account-border-light: $colour-brand-secondary-tint !default;

.account {
    position: relative;
    margin-bottom: 50px;

    &-header {
        padding-bottom: 12px;

        @include respond-to($screen-size-small) {
            padding-top: 12px;
            padding-bottom: 0;
        }
    }

    &.account-login {
        margin: 0 auto 110px;
        padding: 0.5rem 2rem 2rem;
        background: $account-background-regular;
        border: 2px solid $account-border-light;
        border-radius: 0 0 10px 10px;

        &:before {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            border-bottom: 12px solid $account-border-light;
            border-radius: 0 0 5px 5px;
            content: "";
        }

        h3 {
            margin: 50px auto 30px;
            font-weight: $fw-regular;
            font-size: $font-size-r;
        }
    }
}
