@import "../settings";
@import "../00_space/media-queries";

$sae-background-dark: $colour-ui-dark !default;
$sae-colour-dark: $colour-ui-dark !default;

.delivery-option {
    &-progress-indicator {
        text-align: center;
    }

    &-information {
        color: $sae-colour-dark;
        font-size: $font-size-s;
        line-height: $line-height-s;

        @include respond-to($screen-size-small) {
            font-size: $font-size-r;
            line-height: $line-height-r;
        }

        .text-bold {
            font-weight: $fw-semi-bold;
        }

        &-spacer {
            margin: 20px 0;
        }
    }

    &-apps {
        margin-top: 30px;

        @include respond-to($screen-size-small) {
            margin-top: 15px;
        }

        img {
            display: block;
            margin: 0 auto;
        }
    }

    &-form {
        width: 75%;
    }

    &-list {
        margin-left: 1.25rem;
        list-style-position: inside;

        li {
            position: relative;
            padding-left: 1.25rem;
            line-height: $line-height-r;
            list-style: none;

            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 4px;
                height: 4px;
                background-color: $sae-background-dark;
                border-radius: 2px;
                transform: translateY(-50%);
                content: "";
            }
        }
    }
}
