//screen sizes
$screen-size-xs: "xs";
$screen-size-small: "small";
$screen-size-medium: "medium";
$screen-size-large: "large";

$breakpoints: (
    $screen-size-xs: (
        min-width: 576px
    ),
    $screen-size-small: (
        min-width: 766px
    ),
    $screen-size-medium: (
        min-width: 991px
    ),
    $screen-size-large: (
        min-width: 1200px
    )
);

@mixin respond-to($name) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $name) {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $name))} {
            @content;
        }
    } @else {
        // If the key doesn't exist in the map
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. Please make sure it is defined in `$breakpoints` map.';
    }
}
