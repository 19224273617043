@import "./../settings";

$typography-colour-dark: $colour-ui-background !default;
$typography-colour-light: $colour-brand-secondary-tint !default;
$typography-colour-regular: $colour-brand-secondary !default;

h1 {
    margin-bottom: $line-height-xl;
    font-weight: $fw-bold;
    font-size: $font-size-xxl;
    line-height: $line-height-xl;
}

h2 {
    margin-bottom: $line-height-l;
    font-weight: $fw-regular;
    font-size: $font-size-xl;
    line-height: $line-height-l;
}

h3 {
    font-weight: $fw-light;
}

h1,
h2,
h3 {
    font-family: $font-title;
}

h4 {
    font-weight: $fw-regular;
    font-size: $font-size-r;
    font-family: $font-copy;
}

p,
a,
ul,
li,
ol {
    margin-bottom: $line-height-r;
    font-weight: $fw-regular;
    font-size: $font-size-r;
    line-height: $line-height-r;
}

small {
    margin-bottom: $line-height-xs;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
}

a {
    color: $typography-colour-regular;
}

.strong {
    font-weight: $fw-bold;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.underlined {
    text-decoration: underline;
}

.copy {
    &-small {
        font-size: $font-size-xs;
        line-height: $line-height-xs;
    }

    &-small-2 {
        font-weight: $fw-bold;
        font-size: $font-size-xs;
        line-height: $line-height-xs;
    }

    &-medium {
        font-size: $font-size-s;
        line-height: $line-height-s;
    }

    &-medium-2 {
        font-weight: $fw-medium;
        font-size: $font-size-s;
        line-height: $line-height-s;
    }

    &-body {
        font-size: $font-size-r;
        line-height: $line-height-r;
    }

    &-body-2 {
        font-weight: $fw-bold;
        font-size: $font-size-r;
        line-height: $line-height-r;
    }

    &-title {
        font-size: $font-size-xl;
        line-height: $line-height-l;
    }

    &-title-2 {
        font-weight: $fw-bold;
        font-size: $font-size-xl;
        line-height: $line-height-l;
    }

    &-headline {
        font-size: $font-size-xxl;
        line-height: $line-height-xl;
    }

    &-headline-2 {
        font-weight: $fw-bold;
        font-size: $font-size-xxl;
        line-height: $line-height-xl;
    }

    &-number {
        font-weight: $fw-bold;
        font-size: $font-size-l;
        line-height: $line-height-m;
    }
}

.strikethrough {
    position: relative;

    &:before {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        border-color: inherit;
        border-top: 2px solid;
        transform: rotate(-5deg);
        content: "";
    }

    &-promo {
        color: $typography-colour-light;
    }

    &-grey {
        color: darken($typography-colour-dark, 40%);
        font-weight: $fw-regular;
        font-size: $font-size-s;
    }
}

.break-out {
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    hyphens: auto;
}

.font-emphasis {
    letter-spacing: 0.3px;
    text-shadow: 0 0.3px, 0.3px 0, 0.3px 0.3px;
}

.font-emphasis-grey {
    color: #678 !important;
    text-shadow: 0 0.1px #678;
}

.font-emphasis,
.font-emphasis-grey {
    font-weight: 500 !important;
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u-1: unit($min-vw);
    $u-2: unit($max-vw);
    $u-3: unit($min-font-size);
    $u-4: unit($max-font-size);

    @if $u-1 == $u-2 and $u-1 == $u-3 and $u-1 == $u-4 {
        font-size: $min-font-size;

        @media screen and (min-width: $min-vw) {
            font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        }

        @media screen and (min-width: $max-vw) {
            font-size: $max-font-size;
        }
    }
}
