//Grand Central overrides

$body-copy: #48484a;
$body-copy-light: #fff;
$primary-1: #333132;
$primary-2: #ea7400;
$primary-3: #a6cede;
$primary-4: #f28c00 !default;
$main-cta: #e02900;
$secondary-1: #ffddb5;
$secondary-2: #f5f5f5;
$secondary-3: #e8dac7;
$background: rgba(#f8f8f8, 0.5);
$accent-1: #b4ed10;
$accent-1-tint: #e9fab8;
$accent-2: #fcf500;
$accent-2-tint: #fefcb3;
$accent-3: #c50220;
$accent-3-tint: #ebbec5;
//promo
$accent-4: #a6cede;
$accent-4-tint: #ddecf2;

$border-radius: 5px;

//TOC OVERRIDES

////src/modules/app/homepage/homepage.component.scss
$homepage-background: #f5f5f5;
$homepage-colour-dark: #f5f5f5;
$homepage-colour-light: #333132;
//
////src/modules/booking/components/payment-form/payment-form.component.scss
//$payment-form-background: #fff;
$payment-form-border: #e8dac7;
//$payment-form-colour-dark: #27334a;
$payment-form-colour-light: #834c00;
//$payment-form-link: #834c00;
//
////src/modules/booking/components/payment-summary/payment-summary.component.scss
//$payment-summary-border: #e2dbe9;
$payment-summary-link: #834c00;
//
////src/modules/booking/components/travel-assistance-request/travel-assistance-request.component.scss
//$travel-assistance-background: #fff;
$travel-assistance-border-light: #e8dac7;
//$travel-assistance-border-dark: #6c4399;
//
////src/modules/booking/pages/confirmation/confirmation.component.scss
//$confirmation-background: #fff;
$confirmation-border: #e8dac7;
//$confirmation-success: #97d700;
//
////src/modules/booking/pages/order-summary/order-summary.component.scss
//$order-summary-background: #27334a;
//$order-summary-colour: #fff;
//
////src/modules/shared/components/address-finder/address-finder.component.scss
//$address-finder-colour: #27334a;
//
////src/modules/shared/components/booking-steps/booking-steps.component.scss
$booking-steps-background-regular: #fff;
$booking-steps-connector: #000;
$booking-steps-background-light: #f28c00;
$booking-steps-colour-dark: #fff;
$booking-steps-colour-regular: #f28c00;
//$booking-steps-colour-light: #ffddb5;
$booking-steps-label-colour-dark: #000;
//
////src/modules/shared/components/claim-account-form/claim-account-form.component.scss
//$claim-account-form-border: #e2dbe9;
//
////src/modules/shared/components/journey-summary-leg/journey-summary-leg.component.scss
//$hr-border-colour: #27334a;
$leg-summary-colour-dark: #333132;
//$leg-summary-colour-regular: #fff;
$leg-summary-link: #834c00;
//
////src/module/shared/components/loading-indicator/loading-indicator.scss
$loading-colour-dark: #ea7400;
$loading-colour-dark-shaded: #333132;
$loading-colour-regular: #ea7400;
$loading-colour-regular-shaded: #ea7400;
$loading-colour-x-light: #ffddb5;
$loading-colour-x-light-shaded: #ffddb5;
//$loading-colour-xx-light: #fff !default;
//$loading-colour-xx-light-shaded: #f2f2f3 !default;
//
////src/modules/shared/components/journey-summary/journey-summary.component.scss
//$journey-summary-background-dark: #27334a;
//$journey-summary-background-light: #fdeeda;
//$journey-summary-background-regular: #fff;
$journey-summary-border: #e8dac7;
//$journey-summary-colour-dark: #27334a;
//$journey-summary-colour-light: #6c4399;
//
////src/modules/booking/pages/seats-and-extras/delivery-form/delivery-form.component.scss
$delivery-form-colour: #834c00;
$content-border-light: #e8dac7;
$content-background-light: #e8dac7;
//
////src/modules/booking/pages/seats-and-extras/seat-form/seat-form.component.scss
//$seat-from-border: #e2dbe9;
//
////src/modules/booking/pages/seats-and-extras/travel-card-form/travel-card-form.component.scss
//$travel-card-border: #e2dbe9;
//
////src/modules/shared/components/basket/basket.component.scss
//$basket-background: #fff;
//$basket-border: #e2dbe9;
//$basket-colour-x-dark: #27334a;
//$basket-colour-x-light: #fff;
//$basket-colour-light: #6c4399;
//$basket-summary-background: #2a183d;
//$basket-summary-border: #6c4399;
//$basket-summary-colour: #fff;
//
////src/modules/shared/components/journey-leg/journey-leg.component.scss
//$journey-leg-background: #e2dbe9;
//
////src/modules/shared/components/season-ticket/season-ticket.component.scss
//$season-ticket-background: #e2dbe9;
//$season-ticket-background-active: #6c4399;
//$season-ticket-colour-active: #fff;
//$season-ticket-border: #e2dbe9;
//
////src/modules/season/components/season-extras/photocard-form/photocard-form.component.scss
//$photocard-border: #6c4399;
//$photocard-colour: #fff;
//
////src/modules/season/components/season-ticket-option/season-ticket-option.component.scss
//$season-ticket-md-background-active: #6c4399;
//$season-ticket-md-colour-active: #fff;
//$season-ticket-md-border: #e2dbe9;
//
////src/modules/season/pages/season-search/season-search.component.scss
//$fare-grid-background: #f3f3f3;
//
////src/modules/shared/components/journey-adjust/journey-adjust.component.scss
//$mobile-journey-background: #2a183d;
//$mobile-journey-colour: #fff;
//
////src/modules/search/components/mobile-mixing-deck/leg-fare-item/leg-fare-item.component.scss
//$leg-fare-item-background: #fff;
$leg-fare-item-border: #e8dac7;
//$leg-fare-item-colour-dark: #27334a;
$leg-fare-item-colour-light: #834c00;
//
////src/modules/search/components/mobile-mixing-deck/leg-fares/leg-fares.component.scss
//$leg-fares-button-background-regular: #fff;
//$leg-fares-button-background-active: #6c4399;
//$leg-fares-button-border: #6c4399;
//$leg-fares-button-colour-inactive: #6c4399;
//$leg-fares-button-colour-active: #fff;
//
////src/modules/search/components/mobile-mixing-deck/leg-solution/leg-solution.component.scss
//$leg-solution-background: #fff;
$leg-solution-border: #e8dac7;
//$leg-solution-colour-dark: #27334a;
$leg-solution-colour-light: #834c00;
//$leg-solution-colour-success: #97d700;
//$leg-solution-error: #d20020;
//
////src/modules/search/components/mobile-mixing-deck/mobile-mixing-deck.component.scss
//$mmd-no-fares-background: #fadcae;
//$mmd-no-fares-border: #dc9100;
//
////src/modules/search/components/mobile-mixing-deck/outgoing-leg-selector/outgoing-leg-selector.component.scss
//$outgoing-leg-selector-background: #fff;
//
////src/modules/search/components/mobile-mixing-deck/return-leg-selector/return-leg-selector.component.scss
//$return-leg-selector-background: #fff;
//
////src/modules/modal/components/modals/generic/generic-modal.component.scss
//$generic-modal-background-dark: #fadcae;
//$generic-modal-background-light: #fff;
//$generic-modal-border-dark: #dc9100;
//$generic-modal-border-light: #6c4399;
//
////src/modules/modal/components/modals/help-modal/help-modal.component.scss
//$help-modal-background: #fff;
//$help-modal-border-dark: #6c4399;
//$help-modal-border-light: #e2dbe9;
//
////src/modules/modal/components/modals/mixing-deck-row-details/mixing-deck-row-details-modal.scss
//$mdrd-modal-background: #fff;
//$mdrd-modal-border-dark: #6c4399;
//$mdrd-modal-border-light: #e2dbe9;
$mdrd-modal-border-radius: 4px;
//
////src/modules/modal/components/modals/mixing-deck/mixing-deck-model.component.scss
//$md-background-dark: #f3f3f3;
//$md-background-regular: #fff;
//$md-border-dark: #6c4399;
//$md-border-light: #e2dbe9;
//
////src/modules/modal/components/modals/promo-modal/promo-modal.component.scss
//$promo-modal-background: #fff;
//$promo-modal-border-dark: #10d453;
//$promo-modal-border-light: #c5f2ce;
//
////src/modules/modal/components/modals/prompt/prompt.component.scss
//$prompt-background: #fff;
//
////src/modules/modal/components/modals/qtt-modal/qtt-modal.component.scss
$qtt-modal-background: #f5f5f5;
$qtt-modal-colour-x-light: #333132;
//
////src/modules/modal/components/modals/qtt-st-modal/qtt-st-modal.component.scss
//$qtt-st-modal-background: #2a183d;
//$qtt-st-modal-colour-x-light: #fff;
//
////src/modules/modal/components/modals/session-expired/session-expired.component.scss
//$session-expired-background: #fadcae;
//$session-expired-border: #dc9100;
//
////src/modules/modal/components/modals/standard-error/standard-error-modal.component.scss
//$standard-error-modal-background-light: #fadcae;
//$standard-error-modal-background-regular: #f3f3f3;
//$standard-error-modal-border-light: #dc9100;
//$standard-error-modal-border-dark: #27334a;
//
////src/modules/modal/components/ui-modal/uimodal.component.scss
//$ui-modal-background: #27334a;
//
////src/assets/styles/00_space/_globals.scss
//$dropdown-header-background: #6c4399;
//
////src/assets/styles/04_organisms/_account-form.scss
//$account-form-border-dark: #27334a;
//$account-form-border-light: #6c4399;
//$account-form-colour-dark: #27334a;
//
////src/assets/styles/04_organisms/_account.scss
//$account-background-regular: #fff;
//$account-border-light: #e2dbe9;
//
////src/modules/account/account.component.scss
//$account-background-regular: #fff;
$account-background-inactive: rgba($secondary-1, 0.25);
//$account-background-active: #fff;
$account-border-light: #e8dac7;
$account-colour-inactive: #25303a;
$account-colour-active: #25303a;
//
////src/modules/account/components/account-mobile-nav/account-mobile-nav.component.scss
//$account-mobile-nav-background-regular: #fff;
//$account-mobile-nav-background-dark: #2a183d;
//$account-mobile-nav-background-x-dark: #27334a;
//$account-mobile-nav-border-dark: #6c4399;
//$account-mobile-nav-colour-x-dark: #27334a;
//$account-mobile-nav-colour-dark: #2a183d;
//$account-mobile-nav-colour-light: #6c4399;
//$account-mobile-nav-colour-x-light: #fff;
//$account-mobile-nav-error: #d20020;
//
////src/modules/account/components/address-form/address-form.component.scss
//$address-form-error: #d20020;
//
////src/modules/account/components/booking-summary/booking-summary.component.scss
//$booking-summary-background-regular: #fff;
//$booking-summary-background-light-accent: #e2dbe9;
//$booking-summary-border-light: #e2dbe9;
//$booking-summary-border-dark: #f3f3f3;
//$booking-summary-colour-light: #6c4399;
//
////src/modules/account/components/credit-card-form/credit-card-form.component.scss
//$credit-card-form-error: #d20020;
//
////src/modules/account/components/delivery-preference-form/delivery-preference-form.component.scss
//$delivery-preferences-form-border-dark: #27334a;
//$delivery-preferences-form-colour-dark: #27334a;
//
////src/modules/account/pages/account-details/account-details.component.scss
//$account-details-background-active: #6c4399;
$account-details-colour-active: $body-copy;
//
////src/modules/account/pages/account-details/booking/booking-details/booking-details.component.scss
//$booking-details-background-regular: #fff;
//$booking-details-background-light-accent: #e2dbe9;
//$booking-details-border-light: #e2dbe9;
//$booking-details-colour-light: #6c4399;
//
////src/modules/account/pages/account-details/booking/booking.component.scss
//$booking-border-dark: #27334a;
//$booking-border-light: #6c4399;
//
////src/modules/account/pages/register/register.component.scss
//$register-colour-dark: #27334a;
//
////src/modules/account/pages/reset-password/reset-password.component.scss
//$reset-password-colour-x-dark: #2a183d;
//
////src/modules/search/components/calling-points/calling-points.component.scss
//$calling-points-background-regular: #fff;
//$calling-points-background-light: #fef6ec;
$calling-points-background-dark: #e8dac7;
//$calling-points-error: #d20020;
//
////src/modules/search/components/desktop-mixing-deck/desktop-mixing-deck.component.scss
$desktop-mixing-deck-background: #f8f8f8;
//$desktop-mixing-deck-colour-dark: #27334a;
//$desktop-mixing-deck-colour-light: #6c4399;
//
////src/modules/search/components/mixing-deck-pricetable/mixing-deck-pricetable.component.scss
$price-table-colour-light: #48484a;
//
////src/modules/search/components/mixing-deck-row-description/mixing-deck-row-description.component.scss
//$md-rd-background-regular: #fff;
//$md-rd-background-light: #e2dbe9;
//$md-rd-colour-dark: #27334a;
$md-rd-colour-light: #48484a;
//
////src/modules/search/components/mixing-deck-row/mixing-deck-row.component.scss
//$md-row-background-regular: #fff;
$md-row-background-light: #e8dac7;
$md-row-border-light: #e8dac7;
//$md-row-featured: #fff;
//
////src/modules/search/components/mixing-deck-timetable/mixing-deck-timetable.component.scss
//$timetable-background-regular: #fff;
//$timetable-background-open: #c1e3f1;
//$timetable-colour-dark: #27334a;
//$timetable-colour-light: #6c4399;
//
////src/modules/search/components/timetable-cell/timetable-cell.component.scss
//$timetable-cell-background-regular: #fff;
//$timetable-cell-background-light: #e2dbe9;
//$timetable-cell-border-light: #e2dbe9;
//$timetable-cell-colour-dark: #27334a;
$timetable-cell-font-colour: #000;
//$timetable-cell-colour-light: #ea7400;
//$timetable-cell-error: #d20020;
//
////src/modules/search/components/travel-segment/travel-segment.component.scss
$travel-segment-background-desktop: #f8f8f8;
$travel-segment-background-mobile: #e8dac7;
$travel-segment-colour-dark: #25303a;
//$travel-segment-error: #d20020;
//
////src/modules/search/components/travel-segment/travel-segment-stops/travel-segment-stops.component.scss
$travel-segment-stops-background-dark: #25303a;
$travel-segment-stops-background-light-mobile: #ffddb5;
$travel-segment-stops-background-light-desktop: #f8f8f8;
$travel-segment-stops-border-dark: #25303a;
//$travel-segment-steps-error: #d20020;

//
////src/modules/search/pages/journey-search/journey-search.component.scss
//$fare-grid-background: #f3f3f3;
//
////src/assets/styles/04_organisms/_qtt.scss
//$qtt-background-regular: #fff;
//$qtt-border-dark: #6c4399;
$qtt-colour-dark: #834c00;
//$qtt-colour-light: #e2dbe9;
$qtt-colour-x-light: #333132;
$qtt-swap-background: #ea7400;
$qtt-swap-icon: #fff;
$qtt-radius: 4px;

//
////src/modules/shared/components/calendar-card/calendar-card.component.scss
//$calendar-card-background-dark: #6c4399;
//$calendar-card-background-light: #e2dbe9;
//$calendar-card-colour-dark: #27334a;
//$calendar-card-colour-light: #fff;
//
////src/modules/shared/components/calendar/calendar.component.scss
//$calendar-background-regular: #fff;
//$calendar-colour-dark: #27334a;
//
////src/modules/shared/components/numerical-stepper/numerical-stepper.component.scss
//$numerical-stepper-background-light: #e2dbe9;
//$numerical-stepper-background-dark: #6c4399;
//$numerical-stepper-colour-light: #fff;
//
////src/modules/shared/components/passenger-picker/passenger-picker.component.scss
//$passenger-picker-background-dark: #27334a;
//$passenger-picker-background-regular: #fff;
//$passenger-picker-border-dark: #27334a;
//$passenger-picker-border-regular: #6c4399;
//$passenger-picker-colour-dark: #27334a;
//$passenger-picker-colour-regular: #6c4399;
//
////src/modules/shared/components/qtt-toggle/qtt-toggle.component.scss
//$qtt-toggle-background-light: #e2dbe9;
//$qtt-toggle-colour-dark: #2a183d;
//$qtt-toggle-colour-light: #e2dbe9;
//
////src/modules/shared/components/railcard-picker/railcard-picker.component.scss
//$railcard-picker-background-regular: #f3f3f3;
//$railcard-picker-border-light: #e2dbe9;
//$railcard-picker-colour-regular: #6c4399;
//
////src/modules/shared/components/select/select.component.scss
//$select-background-light: #e2dbe9;
//$select-background-regular: #fff;
$select-border-dark: #333132;
$select-border-light: #333132;
//$select-colour-dark: #27334a;
//$select-colour-light: #6c4399;
$select-colour-x-light: #333132;
//$select-error: #d20020;
//
////src/modules/shared/components/station-picker/station-picker.component.scss
//$station-picker-background-regular: #fff;
//$station-picker-colour-dark: #27334a;
//$station-picker-colour-regular: #6c4399;
//$station-picker-colour-light: #fff;
//
////src/assets/styles/02_atoms/_buttons.scss
//$btn-background-dark: #834c00;
//$btn-background-x-dark: #e2dbe9;
//$btn-background-light: #ec1f40;
//$btn-background-regular: #fff;
//$btn-border-dark: #6c4399;
//$btn-border-light: #ec1f40;
$btn-colour-dark: #000;
$btn-colour-link: #834c00;
//$btn-colour-light: #ec1f40;
//$btn-colour-x-light: #d20020;
//$btn-colour-xx-light: #ebbec5;
//$btn-colour-regular-dark: #27334a;
//$btn-colour-regular-light: #fff;
$btn-colour-regular-inverted: #000;
$btn-border-radius: 4px;
//
////src/assets/styles/02_atoms/_checkbox.scss
$checkbox-background-dark: #ea7400;
//$checkbox-background-light: #fff;
//$checkbox-background-regular: #6c4399;
//$checkbox-border-light: #fff;
//$checkbox-border-regular: #6c4399;
//$checkbox-colour-dark: #27334a;
//$checkbox-colour-light: #fff;
//$checkbox-colour-regular: #6c4399;
//
////src/assets/styles/02_atoms/_inputs.scss
//$input-background-dark: #27334a;
//$input-background-light: #fff;
$input-border-dark: #333132;
$input-border-light: #333132;
//$input-border-regular: #6c4399;
//$input-colour-dark: #27334a;
$input-colour-light: #333132;
//$input-error: #d20020;
$input-border-color: #333132;
//
////src/assets/styles/02_atoms/_labels.scss
//$label-colour-dark: #27334a;
//
////src/assets/styles/02_atoms/_radios.scss
//$radio-background-dark: #27334a;
//$radio-background-light: #fff;
//$radio-background-regular: #6c4399;
//$radio-border-dark: #27334a;
//$radio-border-light: #fff;
//$radio-border-regular: #6c4399;
//$radio-colour-dark: #27334a;
//$radio-colour-light: #fff;
$radio-colour-regular: #000;
//
////src/assets/styles/02_atoms/_typography.scss
//$typography-colour-dark: #f3f3f3;
//$typography-colour-light: #e2dbe9;
$typography-colour-regular: #834c00;
//
////src/assets/styles/03_molecules/_accordion.scss
$accordion-colour-regular: #834c00;
//
////src/assets/styles/04_organisms/_content.scss
//$content-background-dark: #f3f3f3;
//$content-background-light: #e2dbe9;
//$content-background-regular: #fff;
//$content-border-dark: #f3f3f3;
//$content-border-x-dark: #27334a;
//$content-border-light: #e2dbe9;
//$content-colour-dark: #27334a;
$content-colour-regular: #834c00;
$content-box-shadow: none;
//
////src/assets/styles/04_organisms/_mixing-deck.scss
//$md-background-light: #e2dbe9;
//$md-background-regular: #fff;
//$md-border-light: #e2dbe9;
//$md-border-regular: #6c4399;
//
////src/assets/styles/04_organisms/_seats-and-extras.scss
//$sae-background-dark: #27334a;
//$sae-colour-dark: #27334a;
//
////src/modules/account/pages/login/login.component.scss
$toc-dependant-label: none;
//
////src/modules/shared/search/components/passenger-picker/passenger-picker.component.scss
$passenger-picker-icon-colour: $primary-2;
//
////src/modules/booking/components/delivery-selection
// $delivery-selection-confirmation-border: #e8dac7;
$delivery-selection-confirmation-background: #fdeeda;
$colour-accent-promo-badge: $accent-4;
//
$qtt-calendar-picker-focus-color: $body-copy;
//
$primary-focus-outline-color: $main-cta;
$secondary-focus-outline-color: $primary-2;
$desktop-mixing-deck-colour-h2: $body-copy;
