@import "toc-overrides";

// Ace Settings
$value-btn-border-radius-medium: 10;
$input-border-color: rgba($input-border-dark, 0.25) !default;
$input-default-settings: 1px solid $input-border-color !default;

// spacing
$spacing: 16px;
$border-radius: 10px !default;

//MAIN COLOUR PALETTE
$body-copy: #27334a !default;
$body-copy-light: #fff !default;
$primary-1: #2a183d !default;
$primary-2: #6c4399 !default;
$primary-3: #41b6e6 !default;
$primary-4: #f28c00 !default;
$main-cta: #e41f40 !default;
$secondary-1: #e2dbe9 !default;
$secondary-2: #c1e3f1 !default;
$secondary-3: #c6b8d4 !default;
$background: #f3f3f3 !default;
$accent-1: #97d700 !default;
$accent-1-tint: #deeeb9 !default;
$accent-2: #dc9100 !default;
$accent-2-tint: #fadcae !default;
$accent-3: #d20020 !default;
$accent-3-tint: #ebbec5 !default;
//promo
$accent-4: #10d453 !default;
$accent-4-tint: #c5f2ce !default;

// colours
$colour-ui-background: $background;
$colour-ui-cta: $main-cta;
$colour-ui-dark: $body-copy;
$colour-ui-light: $body-copy-light;

$colour-brand-primary: $primary-1;
$colour-brand-secondary: $primary-2;
$colour-brand-secondary-tint: $secondary-1;

$colour-accent-success: $accent-1;
$colour-accent-success-tint: $accent-1-tint;
$colour-accent-default: $primary-3;
$colour-accent-default-tint: $secondary-2;
$colour-accent-utility: $secondary-3;
$colour-accent-warning: $accent-2;
$colour-accent-warning-tint: $accent-2-tint;
$colour-accent-error: $accent-3;
$colour-accent-error-tint: $accent-3-tint;

$colour-accent-promo: $accent-4;
$colour-accent-promo-tint: $accent-4-tint;

//fonts
@font-face {
    font-family: font-title;
    src: url("/assets/fonts/font-title.woff") format("woff"); /* Pretty Modern Browsers */
}

@font-face {
    font-family: font-copy;
    src: url("/assets/fonts/font-copy.woff") format("woff"); /* Pretty Modern Browsers */
}

@font-face {
    font-family: font-numeral;
    src: url("/assets/fonts/font-numerals.woff") format("woff"); /* Pretty Modern Browsers */
}

$font-title: "font-title", sans-serif;
$font-copy: "font-copy", sans-serif;
$font-numeral: "font-numeral", sans-serif;

//Font weights:
$fw-hairline: 100;
$fw-ultra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-ultra-bold: 800;
$fw-black: 900;

//Font sizes:
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-r: 16px;
$font-size-l: 18.5px;
$font-size-xl: 20px;
$font-size-xxl: 24px;

//Line heights
$line-height-xs: 16px;
$line-height-s: 18px;
$line-height-r: 20px;
$line-height-m: 24px;
$line-height-l: 28px;
$line-height-xl: 32px;

//Border Radius
$modal-border-radius: 1.25rem;

//Box Shadows
$component-box-shadow: 0 2px 4px 0 rgba(37, 48, 58, 0.2);

//notification-box
$notification-box-narrow: 85%;

$flex-icon-height: 24px;

// calendar
$qtt-calendar-picker-focus-color: $body-copy-light !default;

// focus
$primary-focus-outline-color: $main-cta !default;
$secondary-focus-outline-color: $primary-2 !default;

// Button
$btn-radius: $btn-border-radius !default;
$btn-background-dark: $colour-brand-secondary !default;
$btn-background-x-dark: $colour-brand-secondary-tint !default;
$btn-background-light: $colour-ui-cta !default;
$btn-background-regular: $colour-ui-light !default;
$btn-border-dark: $colour-brand-secondary !default;
$btn-border-light: $colour-ui-cta !default;
$btn-colour-dark: $colour-brand-secondary !default;
$btn-colour-link: $colour-brand-secondary !default;
$btn-colour-light: $colour-ui-cta !default;
$btn-colour-x-light: $colour-accent-error !default;
$btn-colour-xx-light: $colour-accent-error-tint !default;
$btn-colour-regular-dark: $colour-ui-dark !default;
$btn-colour-regular-light: $colour-ui-light !default;
$btn-colour-regular-inverted: $colour-ui-light !default;
