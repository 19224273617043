@import "./../settings";

$accordion-colour-regular: $colour-brand-secondary !default;

.accordion {
    position: relative;

    &-head {
        color: $accordion-colour-regular;
        cursor: pointer;
    }

    &-content {
        width: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;

        &-visible {
            width: auto;
            height: auto;
            overflow: visible;
            visibility: visible;
        }
    }
}
