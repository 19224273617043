@import "./../settings";

$label-colour-dark: $colour-ui-dark !default;

.label {
    position: relative;
    color: $label-colour-dark;
    font-size: $font-size-r;
    line-height: $line-height-r;
}
