@import "./../settings";
@import "./../00_space/media-queries";
@import "./../00_space/helpers";

$qtt-background-regular: $colour-ui-light !default;
$qtt-colour-dark: $colour-brand-secondary !default;
$qtt-colour-light: $colour-brand-secondary-tint !default;
$qtt-colour-x-light: $colour-ui-light !default;
$qtt-border-dark: $colour-brand-secondary !default;

.qtt {
    position: relative;

    &-dropdown-header {
        margin-top: 0;

        &:before {
            display: none;
        }
    }

    &-open-return {
        position: absolute;
        top: -20px;
        left: 50%;
        z-index: 10;
        display: block;
        transform: translateX(-50%);

        @include respond-to($screen-size-small) {
            top: 15px;
        }
    }

    &-calendar-view-buttons {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
    }

    &-calendar-view {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        display: block;
        height: 100%;
        overflow: auto;
        background-color: $qtt-background-regular;

        @include respond-to($screen-size-small) {
            position: relative;
            margin-top: 5px;
            overflow: visible;
        }

        ace-travel-time-option {
            display: block;
            margin: 0 10px;
        }

        ace-button {
            display: inline-block;
            width: auto;
            margin: 10px 10px 10px 0;
            text-align: center;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .row {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        &::before,
        &::after {
            display: none;
        }
    }

    .col-12 {
        width: 100%;
    }

    .col-left,
    .col-right {
        width: 47.5%;
    }

    &-wrapper {
        margin: auto;
    }

    &-calendar {
        width: 100%;

        &-date {
            font-weight: $fw-semi-bold;
        }

        .input {
            height: 100%;
            cursor: pointer;
            user-select: none;

            &-applicable {
                position: relative;
            }

            &-disabled {
                cursor: pointer;
            }
        }

        span {
            display: block;
        }

        .add-return,
        .cancel-return {
            position: absolute;
            top: 0;
            right: 1px;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            border-left: $input-default-settings;
            cursor: pointer;

            @include respond-to($screen-size-small) {
                width: 40px;
            }

            svg {
                display: block;
                width: 18px;
                height: 18px;
            }
        }

        .add-return {
            color: $qtt-colour-x-light;
        }

        .cancel-return {
            color: $qtt-border-dark;
        }
    }

    &-depart {
        display: block;
        margin: 8px 0;
    }

    &-passenger {
        margin: 16px 0 0;
    }

    &-submit {
        margin-top: 20px;
        text-align: center;

        ::ng-deep {
            button {
                padding: 12px 28px;

                @include respond-to($screen-size-small) {
                    padding: 10px 24px;
                }
            }
        }
    }
}

// all tablet/desktop styling are now applied conditionally, if .is-horizontal modifier class is present
.qtt-toggle {
    max-width: 414px;
    margin: 0 auto;
}

.is-horizontal.qtt-toggle {
    @include respond-to($screen-size-small) {
        max-width: none;
    }
}

.is-horizontal .qtt {
    @include respond-to($screen-size-small) {
        .qtt-dropdown-header {
            &:before {
                display: block;
            }
        }

        .qtt-calendar {
            min-height: 0;
            margin-top: 0;
        }

        .qtt-calendar-view {
            position: relative;
            height: auto;
            margin-top: 5px;
            overflow: visible;
        }

        .col-left,
        .col-right {
            width: 49.2%;
        }

        .qtt-wrapper {
            max-width: 796px;
            min-height: 0;
            padding: 10px;
        }

        .qtt-station {
            .qtt-station-picker {
                float: left;
                width: 44.8453608247%;
                margin-right: 5.15463917526%;

                &:last-of-type {
                    margin-right: 0;
                    margin-left: 5.15463917526%;
                }
            }
        }

        .qtt-calendar {
            .input {
                min-height: 24px;
            }

            span {
                display: inline;
            }

            .add-return,
            .cancel-return {
                svg {
                    width: 28px;
                    height: 18px;
                    margin-top: 0;
                }
            }
        }

        .qtt-passenger {
            float: left;
            clear: both;
            width: 49%;
        }

        .qtt-submit {
            float: right;
        }
    }
}
