@import "../settings";
@import "../00_space/media-queries";

$account-form-border-dark: $colour-ui-dark !default;
$account-form-border-light: $colour-brand-secondary !default;
$account-form-colour-dark: $colour-ui-dark !default;

.account-form {
    margin: 25px 0;

    .form-content {
        margin-top: 3rem;
        color: $account-form-colour-dark;
        font-size: $font-size-s;
        line-height: $line-height-r;

        a {
            // font-weight: $fw-bold;
            font-weight: 500;
            font-size: $font-size-s;
            letter-spacing: 0.3px;
            text-decoration: none;
            text-shadow: 0 0.3px, 0.3px 0, 0.3px 0.3px;

            &:hover {
                text-decoration: underline;
                box-shadow: none;
            }
        }

        b {
            // font-weight: $fw-bold;
            font-weight: 500;
            letter-spacing: 0.3px;
            text-shadow: 0 0.3px, 0.3px 0, 0.3px 0.3px;
        }

        &-topless {
            margin-top: 1rem;

            @include respond-to($screen-size-small) {
                margin-top: 0;
            }
        }
    }

    &-selection {
        position: relative;

        @include respond-to($screen-size-small) {
            min-width: 400px;
        }

        @include respond-to($screen-size-large) {
            float: left;
            width: 75%;
            margin-right: 5%;
            padding-right: 80px;
        }

        span {
            display: block;
            margin-bottom: 8px;

            @include respond-to($screen-size-small) {
                display: inline-block;
                margin-bottom: 0;
                padding-right: 5px;
            }
        }
    }
}

.input-group {
    margin-bottom: 14px;

    &-row {
        display: flex;
        flex-direction: row;
    }

    label {
        display: inline-block;
        margin-bottom: 8px;
        color: $account-form-colour-dark;
    }

    &.input-group-date,
    &.input-group-expire {
        .input {
            display: inline-block;
            width: 50px;
        }
    }

    &.input-group-date:last-of-type {
        width: 60px;
    }

    &-buttons {
        display: flex;
        flex-direction: column;

        ace-button {
            margin: 10px auto;
        }
    }
}

// FORM SELECTION
.form-selection {
    display: table;
    width: 100%;
    margin: 10px 0;
    color: rgba($account-form-colour-dark, 0.7);

    @include respond-to($screen-size-small) {
        margin: 20px 0;

        .text-right {
            text-align: left;
        }

        &:first-of-type {
            margin-top: 0;
        }
    }

    > div {
        display: table;
        width: 100%;
    }

    &:last-of-type {
        margin: 10px 0 0;

        @include respond-to($screen-size-small) {
            margin: 20px 0 0;
        }
    }

    &-bordered {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px dotted rgba($account-form-border-dark, 0.7);

        @include respond-to($screen-size-small) {
            margin-bottom: 20px;
            padding-bottom: 20px;

            .text-right {
                text-align: right;
            }
        }

        &:first-of-type {
            margin-top: 15px;
            padding-top: 15px;
            border-top: 1px dotted rgba($account-form-border-dark, 0.7);

            @include respond-to($screen-size-small) {
                margin-top: 20px;
                padding-top: 20px;
            }
        }

        .form-selection-label {
            width: 100px;
        }
    }

    &-label,
    &-content {
        display: table-cell;

        @include respond-to($screen-size-large) {
            vertical-align: top;
        }
    }

    &-label {
        padding-right: 10px;
        font-weight: $fw-bold;
        vertical-align: middle;

        @include respond-to($screen-size-small) {
            width: 210px;
        }
    }

    &-remove {
        display: block;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .form-option {
        white-space: pre-wrap;
        word-break: break-all;
    }
}
