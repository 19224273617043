/* ************************************** */
/* Inputs
/* ************************************** */
@import "settings";
@import "00_space/media-queries";

$input-background-dark: $colour-ui-dark !default;
$input-background-light: $colour-ui-light !default;
$input-border-dark: $colour-ui-dark !default;
$input-border-light: $colour-ui-light !default;
$input-border-regular: $colour-brand-secondary !default;
$input-colour-dark: $colour-ui-dark !default;
$input-colour-light: $colour-ui-light !default;
$input-error: $colour-accent-error !default;
$input-success: $colour-accent-success !default;

.input {
    display: inline-block;
    width: 100%;
    min-height: 1.5em;
    padding: 8px 11px 8px 8px;
    color: $input-colour-dark;
    font-weight: $fw-regular;
    font-size: $font-size-r;
    line-height: $line-height-r;
    white-space: nowrap;
    background-color: $input-background-light;
    border: $input-default-settings;
    border-radius: 3px;

    @include respond-to($screen-size-small) {
        font-size: $font-size-s;
    }

    &::placeholder {
        color: rgba($input-colour-dark, 0.25);
    }

    &:focus {
        color: $input-colour-dark;
        border-color: $input-border-regular;
        box-shadow: none;
    }

    &:disabled {
        color: rgba($input-colour-dark, 0.5);
        background-color: rgba($input-background-dark, 0.1);
        border: 1px solid rgba($input-border-dark, 0);
    }

    &-disabled {
        color: $input-colour-light;
        background: none;
        border: 1px solid $input-border-light;
    }

    &-error {
        border-color: $input-error;

        &-text {
            display: block;
            margin-top: 8px;
            color: $input-error;
            font-size: $font-size-s;
            text-align: left;
        }
    }

    &-success {
        border-color: $input-success;
    }
}
