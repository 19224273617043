@import "./../settings";
@import "./media-queries";

$dropdown-header-background: $colour-brand-secondary !default;

a {
    &:focus {
        outline: #{$primary-focus-outline-color} 2px auto;
    }
}

a.external-link::after,
.external-link a::after {
    display: inline-flex;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    vertical-align: middle;
    background-color: $colour-brand-secondary;
    content: "";
    mask-image: url("/assets/images/svg/external-link.svg");
    mask-size: 16px 16px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    padding-top: 60px;
    color: $colour-ui-light;
    text-align: center;
    background: rgba($colour-ui-dark, 0.6);
}

.input {
    &:focus {
        outline: #{$primary-focus-outline-color} 2px auto;
    }
}

.hide {
    display: none;

    &-tablet-up {
        display: block;

        @include respond-to($screen-size-small) {
            display: none;
        }
    }
}

.disable-scroll {
    overflow: hidden;
}

.clearfix {
    &:after {
        display: block;
        clear: both;
        content: "";
    }
}

.dropdown-header {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 5px;
    margin-top: 5px;
    background-color: $dropdown-header-background;

    &:before {
        position: absolute;
        top: -8px;
        left: 50%;
        width: 50px;
        height: 50px;
        background-color: $dropdown-header-background;
        border-radius: 10px;
        transform: translateX(-50%) rotate(45deg);
        content: "";
    }

    &-reverse {
        margin-top: -48px;

        &:before {
            top: -40px;
        }
    }

    &-left {
        &:before {
            left: 25%;
        }
    }

    &-center {
        &:before {
            right: 0;
            left: 0;
        }
    }

    &-right {
        &:before {
            left: 75%;
        }
    }
}

.is-menu-expanded {
    position: fixed;
    width: 100%;
    height: 100%;
}

// show elids i.e. preview elements with testing hooks
@keyframes emphasise {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(0.9);
        opacity: 0.8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.show-elids [data-elid] {
    position: relative;

    &:after {
        position: absolute;
        top: -24px;
        left: 0;
        z-index: 9000;
        display: inline-block;
        margin: 8px;
        padding: 8px;
        color: #333;
        font-weight: bold;
        font-size: $font-size-xs;
        white-space: nowrap;
        background-color: #fff;
        border: 2px solid #333;
        animation: emphasise 4s infinite ease-in-out;
        content: "elid: " attr(data-elid);
    }

    &:hover:after {
        z-index: 9001;
        border-style: dotted;
    }
}

.is-error {
    color: $accent-3;
}

.penalty-info {
    opacity: 0.6;
}
