@import "./../settings";
@import "./../00_space/media-queries";

$md-background-light: $colour-brand-secondary-tint !default;
$md-background-regular: $colour-ui-light !default;
$md-border-light: $colour-brand-secondary-tint !default;
$md-border-regular: $colour-brand-secondary !default;

.mix-deck {
    &-row {
        border-bottom: solid 2px $md-border-light;

        &-highlight {
            border: 2px solid $md-border-regular;
        }
    }

    &-column {
        display: block;
        float: left;
        width: 33.333333%;

        &-left {
            padding-right: 6px;
        }

        &-right {
            padding-left: 6px;
        }
    }

    &-title {
        text-align: center;
    }

    &-header {
        display: none;
        padding: 0 0 1rem;

        @include respond-to($screen-size-medium) {
            display: block;
        }

        &-stations,
        &-change,
        &-continue {
            display: inline-block;
        }

        &-stations {
            margin-bottom: 0;
            font-size: $font-size-xl;
            font-family: $font-title;

            @include respond-to($screen-size-medium) {
                font-size: $font-size-xxl;
            }
        }

        &-change {
            margin-left: 1rem;
        }

        &-continue {
            float: right;
            margin-top: -0.5rem;
        }

        &-section {
            display: none;
            margin: 0.5rem 0;

            @include respond-to($screen-size-medium) {
                display: block;
            }
        }
    }

    &-body {
        margin-top: 2.5rem;

        &-information {
            &-title {
                margin-bottom: 1.25rem;
                font-weight: $fw-regular;
                font-size: $font-size-r;
                font-family: $font-copy;
            }
        }
    }

    &-box {
        padding: 10px;
        background-color: lighten($colour-brand-secondary-tint, 7.5%);
        border: 1px solid $colour-brand-secondary;
        border-radius: 5px;

        span {
            margin-bottom: 0.5rem;
            font-weight: $fw-bold;

            &:last-of-type {
                margin-bottom: 0;
                font-weight: $fw-regular;
            }
        }
    }

    &-service-class {
        background-color: $md-background-light;

        &-first {
            background-color: $md-background-regular;
        }
    }

    &-fare {
        min-height: 150px;
        background-color: $md-background-regular;
    }
}

.searching {
    padding: 5em;
    font-size: $font-size-r * 2;
    text-align: center;

    ace-loading-indicator {
        display: block;
        margin: 0 auto 1em;
    }
}
