@use "sass:math";
@import "./../settings";
@import "./../00_space/grids";

$content-background-dark: $colour-ui-background !default;
$content-background-light: $colour-brand-secondary-tint !default;
$content-background-regular: $colour-ui-light !default;
$content-border-dark: $colour-ui-background !default;
$content-border-x-dark: $colour-ui-dark !default;
$content-border-light: $colour-brand-secondary-tint !default;
$content-colour-dark: $colour-ui-dark !default;
$content-colour-regular: $colour-brand-secondary !default;
$content-box-shadow: $component-box-shadow !default;

.app-content {
    flex: 1;
}

.content {
    &-header {
        padding: 0 15px;
        overflow: hidden;
        line-height: 55px;
        border-bottom: 1px solid $content-background-light;

        &-complete {
            border-bottom: 1px solid darken($content-border-dark, 10%);
        }

        &-seamless {
            border-bottom-style: none;
        }

        &-flex {
            display: flex;
        }

        .btn {
            color: $content-colour-regular;
            font-size: $font-size-r;
        }

        &-label {
            float: left;
            font-size: $font-size-r;
        }

        &-button,
        &-description {
            float: right;
        }
    }

    &-body {
        position: relative;
        padding: 10px;
        overflow: hidden;
        color: $content-colour-dark;

        @include respond-to($screen-size-small) {
            padding: 20px;
        }

        &-description {
            p:last-of-type {
                margin-bottom: 0;
            }
        }

        span {
            line-height: $line-height-l;
        }

        .braintree-dropin {
            span {
                line-height: unset;
            }
        }

        ul {
            list-style-position: inside;
        }

        ol {
            list-style: none;
            counter-reset: content;

            li {
                margin-bottom: 5px;
                counter-increment: content;

                &:before {
                    display: inline-block;
                    margin-right: 5px;
                    // font-weight: $fw-black;
                    color: #678;
                    font-weight: 500;
                    text-shadow: 0 0.1px #678;
                    content: counter(content, decimal) ". ";
                }
            }
        }
    }

    &-body-loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        padding-top: 40px;
        text-align: center;
        background: rgba($content-background-regular, 0.8);

        p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-bordered {
        margin-bottom: 16px;
        background: $content-background-regular;
        border: solid 2px $content-border-light;
        border-radius: $value-btn-border-radius-medium * 1px;
        box-shadow: $content-box-shadow;
    }

    &-floored {
        position: relative;
        border: solid 2px $content-border-light;
        border-radius: 0 0 12px 12px;

        @include respond-to($screen-size-small) {
            border-top-left-radius: $value-btn-border-radius-medium * 1px;
            border-top-right-radius: $value-btn-border-radius-medium * 1px;
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 6px;
            background-color: $content-background-light;
            border-radius: 0 0 20px 20px;
            content: "";
        }
    }

    &-blank {
        position: relative;
        border: solid 2px darken($content-border-dark, 10%);
        border-radius: 0 0 12px 12px;

        @include respond-to($screen-size-small) {
            border-top-left-radius: $value-btn-border-radius-medium * 1px;
            border-top-right-radius: $value-btn-border-radius-medium * 1px;
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 6px;
            background-color: darken($content-background-dark, 10%);
            border-radius: 0 0 20px 20px;
            content: "";
        }
    }

    &-box {
        margin-bottom: 20px;
        padding: $spacing;
        background-color: $content-background-regular;

        @include respond-to($screen-size-small) {
            padding: 25px (math.div(100, 12)) * 1%;
        }

        h3 {
            margin: 0 auto 10px;
            font-size: $font-size-xl;
            line-height: $line-height-xl;
            text-align: center;

            @include respond-to($screen-size-small) {
                padding: 6px;
            }
        }

        .form-input {
            margin-bottom: 15px;

            label {
                display: block;
                margin-bottom: 8px;
            }
        }
    }

    &-form {
        margin-top: 20px;

        @include respond-to($screen-size-small) {
            padding: 30px 50px;
            border: solid 1px rgba($content-border-x-dark, 0.4);
        }

        @include respond-to($screen-size-large) {
            padding: 30px 100px;
        }

        &-description {
            margin-bottom: 40px;
            text-align: center;
        }

        &-direction,
        &-description {
            float: left;

            p {
                margin: 0;
            }
        }

        &-direction {
            margin-right: 20px;

            p {
                font-weight: $fw-bold;
                font-size: $font-size-xl;
                line-height: $line-height-xl;
            }
        }
    }

    &-toggle {
        position: relative;
        margin-top: 20px;
        overflow: hidden;
        font-weight: $fw-bold;

        @include respond-to($screen-size-small) {
            float: right;

            &-left {
                float: left;
            }
        }

        .extra-seats & {
            margin-top: 5px;
        }

        p {
            float: left;
            margin: 0;
            padding-right: 95px;
            font-weight: $fw-bold;
            line-height: $line-height-xl;

            @include respond-to($screen-size-small) {
                display: inline;
                float: none;
            }
        }

        ace-switch {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            float: right;
        }
    }
}

.page-title {
    margin: 25px 0;
    font-size: $font-size-xxl;
    font-family: $font-title;
    line-height: $line-height-r;
    text-align: center;

    @include respond-to($screen-size-small) {
        font-size: $font-size-r * 2;
        line-height: $font-size-r * 2;
    }
}
